.project {
  display: flex;
  align-items: center;
  margin: 25px 0 40px;
}

.project.right {
  flex-direction: row-reverse;
}

.project-image-container {
  width: calc(100% - 450px);
}

.project.right .project-main-image {
  width: 95%;
  margin-left: 5%;
}
.project.left .project-main-image {
  width: 95%;
  margin-right: 5%;
}

.project-details {
  width: 400px;
  margin-right: 50px;
}

.project-title {
  font-weight: 800;
  text-transform: capitalize;
  font-size: 26px;
  letter-spacing: -0.05em;
}

.project-title, .project-description, .project-list-container {
  padding: 0 5px 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #bcbcbc;
}

.project-description {
  /* font-weight: 500; */
  font-family: 'Open Sans', sans-serif;
  line-height: 22px;
  padding: 0 5px 20px;
  border-bottom: 2px solid #bcbcbc;
  font-size: 14px;
}

.project-list-title {
  font-size: 14px;
  font-weight: 700;
  margin: 5px 0;
}
.project-list {
  list-style: none;
  padding-left: 10px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.project-list li {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
}
.project-list li:not(:last-child):after {
  content: ', ';
  margin-right: 10px;
}
.project-list li:last-child:after {
  content: '.';
}

.project-link-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.project-link-holder {
  height: 60px;
  display: flex;
  align-items: flex-end;
}
.project-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 40px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  padding: 0 20px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 #101011;
  background-color: #dd5265;
  border: 1px solid #af3848;
  border-bottom: 5px solid #af3848;
}
.project-link:hover, .project-link:focus {
  border-bottom-width: 3px;
}
.project-link i {
  margin-right: 10px;
}

@media only screen and (max-width: 1500px) {
  .project-image-container {
    width: calc(100% - 350px);
  }
  
  .project.right .project-main-image {
    width: 98%;
    margin-left: 2%;
  }
  .project.left .project-main-image {
    width: 98%;
    margin-right: 2%;
  }
  
  .project-details {
    width: 300px;
    margin-right: 50px;
  }

  .project-link i {
    display: none;
  }
  .project-link {
    font-size: 12px;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .project {
    flex-direction: column;
  }
  .project.right {
    flex-direction: column;
  }

  .project-image-container {
    width: 100%;
  }
  
  .project.right .project-main-image {
    width: 98%;
    margin-left: 2%;
  }
  .project.left .project-main-image {
    width: 98%;
    margin-right: 2%;
  }
  
  .project-details {
    width: 90%;
    margin-right: 10%;
    margin-left: 10%;
  }

  .project-link i {
    display: inline;
  }
  .project-link {
    font-size: 14px;
    padding: 0 20px;
    margin: 0 10px;
  }
  .project-link-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

@media only screen and (max-width: 550px) {
  .project-link i {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .project-link {
    font-size: 12px;
    padding: 0 15px;
    margin: 0 5px;
  }
  .project-link-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
@media only screen and (max-width: 390px) {
  .project-link i {
    display: inline;
  }
  .project-link {
    font-size: 14px;
    padding: 0 15px;
    margin: 0 5px;
  }
  .project-link-container {
    align-items: center;
    flex-direction: column;
  }
  .project-link-holder {
    height: 52px;
  }
}