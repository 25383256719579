.footer {
  position: absolute;
  padding: 0 12%;
  bottom: -25px;
  background-color: #dfdfdf;
  left: 0;
  right: 0;
  height: 100px;
  box-shadow: 0 -5px 0 0 #1b1b1f1e;
  overflow: hidden;
}

.footer-decoration {
  position: absolute;
  display: inline-block;
  width: 64px;
  height: 8px;
  right: -30px;
  bottom: 0;
  background-color: #af3848;
  transform: rotate(-45deg);
}
.footer-decoration:before {
  position: absolute;
  right: 0;
  bottom: 26px;
  content: '';
  width: 100px;
  height: 8px;
  background-color: #af3848;
}

.footer-decoration:after {
  position: absolute;
  right: 0;
  bottom: 13px;
  content: '';
  width: 64px;
  height: 8px;
  background-color: #af3848;
}

.footer-section {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer li {
  margin: 0 15px;
  width: 50px;
}
.footer a {
  display: inline-block;
  font-weight: 700;
  margin-bottom: 5px;
  color: #1b1b1f;
  text-decoration: none;
}
.footer a:hover, .footer a:focus {
  color: #af3848;
}