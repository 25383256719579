.portfolio-container {
  margin-bottom: 10%;
}

.portfolio-title-container {
  padding-bottom: 5% !important;
}

.projects-list {
  list-style: none;
  padding: 0;
  margin: 0;
}