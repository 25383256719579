.header {
  position: relative;
  background-color: #1b1b1f;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  height: 90px;
  box-shadow: 0 5px 0 0 #1b1b1f33;

  animation: slide-in 1s;
}

.header:before {
  position: absolute;
  left: -30px;
  content: '';
  width: 64px;
  height: 8px;
  background-color: #af3848;
  transform: rotate(-45deg);
}

.header:after {
  position: absolute;
  left: -30px;
  content: '';
  width: 100px;
  height: 8px;
  background-color: #af3848;
  transform: rotate(-45deg);
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  border: 0px solid transparent;
  color: #fff;
  text-decoration: none;
}

.logo-images {
  position: relative;
  height: 50px;
  width: 50px;
}
.logo-image-red-container {
  position: absolute;
  height: 50px;
  width: 0;
  top: 0;
  overflow: hidden;

  transition: width 0.4s;
}

.logo.active .logo-image-red-container {
  width: 50px;
}
.logo:hover .logo-image-red-container, .logo:focus .logo-image-red-container{
  width: 50px;
}

.logo svg {
  overflow: hidden;
}

.logo-text {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.logo-name {
  font-size: 18px;
  font-weight: 700;
}

.logo-title {
  font-size: 14px;
  font-weight: 500;
}

.nav {
  width: 60%;
  height: 90px;
}

.nav-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  height: 100%;
  margin: 0;
}
.nav-list-item {
  margin-right: 7%;
}
.nav-list-icon:not(:last-child) {
  margin-right: 4%;
}

.nav-link, .nav-link-icon {
  display: inline-flex;
  align-items: center;
  height: 60px;
}

.nav-link {
  position: relative;
  transition: color 0.2s;
}
.nav-link.active{
  color: #dd5265;
}
.nav-link:after {
  position: absolute;
  bottom: 10px;
  left: 51%;
  right: 51%;
  content: '';
  height: 2px;
  background-color: #af3848;

  transition: left 0.5s, right 0.5s;
}

.nav-link:not(.active):hover:after, .nav-link:not(.active):focus:after {
  right: -20%;
  left: -20%;
}
.nav-link-1, .nav-link-2, .nav-link-3, .nav-link-4, .nav-link-5 {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}

.nav-link-icon {
  padding: 0 10px;
  font-size: 20px;
  transition: color 0.2s;
}
.nav-link-icon:hover, .nav-link-icon:focus {
  color: #dd5265;
}

/* entrance animation */

@keyframes slide-in {
  0% {
    top: -95px;
  }
  20% {
    top: -95px;
  }
  100% {
    position: relative;
    top: 0;
  }
}


.nav-button {
  display: none;

  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  height: 100%;
  padding: 0 10px;

  transition: color 0.2s;
}

.nav-button:hover, .nav-button:focus {
  color: #dd5265;
}

.nav-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .nav {
    width: auto;
  }

  .nav-list.hidden {
    display: none !important;
  }

  .nav-list {
    z-index: 5;

    display: flex;
    text-align: center;
    flex-wrap: wrap;
    overflow: hidden;

    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    height: 0;

    background-color: #31323b;

    box-shadow: 0 5px 0 0 #1b1b1f33;

    transition: height 0.6s;
  }

  .nav-list-item {
    width: 80%;
    margin: 0 10%;
    border-bottom: 1px solid #5f6065;
  }

  .nav-list-icon {
    margin-right: 0 !important;
    width: 33%;
  }

  .nav-list-item, .nav-list-icon {
    padding: 10px 0;
  }

  .nav-list.active {
    height: 242px;
  }

  .nav-button {
    display: block;
  }
}

@media only screen and (max-width: 400px) {
  .logo-text {
    text-indent: -9999px;
  }
}
