
.landing-links {
  padding: 0 10%;
  display: flex;
  align-items: flex-end;
}
.landing-link-holder {
  height: 60px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.landing-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 40px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  padding: 0 20px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 #101011;
  background-color: #dd5265;
  border: 1px solid #af3848;
  border-bottom: 5px solid #af3848;
  margin-right: 10px;
}
.landing-link:hover, .landing-link:focus {
  border-bottom-width: 3px;
}
.landing-link i {
  margin-right: 10px;
}

@media only screen and (max-width: 950px) {
  .landing-links {
    padding: 0;
  }
}

@media only screen and (max-width: 700px) {
  .featured-projects {
    margin-top: 10%;
  }
}

@media only screen and (max-width: 400px) {
  .landing-link i {
    display: none;
  }
}