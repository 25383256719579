.about-me-sub {
  margin-bottom: 100px;
}

.about-me-p {
  margin-top: 40px;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 35px;
  font-family: 'Open Sans', sans-serif;
}