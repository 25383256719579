html {
  display: flex;
  min-height: 100%;
}

body {
  flex: 1;
  background-color: #f1f1f1;
}

* {
  font-family: Montserrat, sans-serif;
}

*:focus {
  outline: none;
}

main {
  padding: 0 10% 105px;
  animation: fade-in 0.4s;
}

#root {
  position: relative;
  height: 100%;
}

.loading-transition {
  z-index: 10;
  position: fixed;
  display: flex;
  top: 0;
  left: 100%;
  right: 0;
  bottom: 0;
  background-color: #31323b;
  
  transition: left 0.5s;
  /* animation: transition-load-in 2s; */
}

.loading-transition.active {
  left: 0;
  animation: transition 0.5s;
}


@keyframes transition {
  0% {
    right: 100%;
    left: 0;
  }
  100% {
    right: 0;
    left: 0;
  }
}

@keyframes transition-load-in {
  0% {
    left: 0;
  }
  50% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

.fade-out {
  transform: scale(0.85, 0.85);
  animation: fade-out 0.4s;
}

@keyframes fade-out {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.85, 0.85);
  }
}

@keyframes fade-in {
  0% {
    transform: scale(0.85, 0.85);
  }
  100% {
    transform: scale(1, 1);
  }
}

@media only screen and (max-width: 700px) {
  main {
    padding: 0 5% 105px;
  }
}