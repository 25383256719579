.about-me-preview {
  padding: 10%;
}

.about-me-title {
  color: #1b1b1f;
  font-size: 60px;
  font-weight: 800;
  letter-spacing: -0.05em;
  line-height: 75px;
  margin: 0;
}

.about-me-subtitle {
  font-size: 18px;
  font-weight: 500;
  color: #31323b;
  letter-spacing: 0.08em;
  margin: 0;
}

@media only screen and (max-width: 950px) {
  .about-me-preview {
    padding: 10% 0;
  }

  .about-me-title {
    font-size: 52px;
    line-height: 60px;
    letter-spacing: -0.03em;
  }
}

@media only screen and (max-width: 700px) {
  .about-me-title {
    font-size: 45px;
    line-height: 55px;
    letter-spacing: -0.03em;
  }

  .about-me-preview {
    padding-top: 30% !important;
  }
}

@media only screen and (max-width: 400px) {
  .about-me-title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.03em;
  }

  .about-me-subtitle {
    font-size: 14px;
  }
}

@media only screen and (min-width: 2000px) {
  .about-me-title {
    font-size: 80px;
    line-height: 90px;
  }

  .about-me-subtitle {
    font-size: 20px;
  }
}